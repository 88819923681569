/* Reset styles */
* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

.App {
  font-family:Verdana, Geneva, Tahoma, sans-serif;
}

.App-link {
  color: #61dafb;
}

/* Global styles */
body {
  font-size: 16px;
  background-color: #4a5366;
}

.bgpage {
  background-color: #f5f5f5;
}

footer {
  color: white;
}

.intro-container {
  align-items: center;
  justify-content: center;
  align-content: center;
  text-align: center;
}
.intro-blurb {
  display: block;
  align-items: center;
  justify-content: center;
  align-content: center;
  text-align: center;
}
